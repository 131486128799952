import React from 'react';
import * as styles from './TermsContent.module.scss';
import PortableText from '@sanity/block-content-to-react';
import Wrapper from '../Wrapper/Wrapper';
import PropTypes from 'prop-types';

const TermsContent = ({ block }) => {
  return (
    <div className={styles.terms}>
      <Wrapper>
        <div className={styles.text}>
          <PortableText blocks={block} />
        </div>
      </Wrapper>
    </div>
  );
};

TermsContent.propTypes = {
  block: PropTypes.array.isRequired,
};

TermsContent.defaultProps = {
  block: null,
};

export default TermsContent;
